

@font-face {
  src: url(./fonts/volta/VoltaModernText-Bold.woff);
  font-family: "bold";
}

@font-face {
  src: url(./fonts/volta/VoltaModernText-Roman.woff);
  font-family: "regular";
}

@font-face {
  src: url(./fonts/volta/VoltaModernText-Medium.woff);
  font-family: "medium";
}

@font-face {
  src: url(./fonts/volta/VoltaModernText-Italic.woff);
  font-family: "italic";
}

@font-face {
  src: url(./fonts/volta/VoltaModernText-BoldItalic.woff);
  font-family: "bolditalic";
}

@font-face {
  src: url(./fonts/volta/VoltaModernText-MediumItalic.woff);
  font-family: "mediumitalic";
}

@font-face {
  src: url(./fonts/volta/VoltaModernDisplay-Light.woff);
  font-family: "light";
}

@font-face {
  src: url(./fonts/volta/VoltaModernDisplay-Black.woff);
  font-family: "displayblack";
}

@font-face {
  src: url(./fonts/volta/VoltaModernDisplay-Bold.woff);
  font-family: "displaybold";
}

@font-face {
  src: url(./fonts/volta/VoltaModernDisplay-Light.woff);
  font-family: "displaylight";
}

@font-face {
  src: url(./fonts/volta/VoltaModernDisplay-Medium.woff);
  font-family: "displaymedium";
}

@font-face {
  src: url(./fonts/volta/VoltaModernDisplay-Roman.woff);
  font-family: "displayregular";
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 18px;
  font-family: regular, Arial, "Helvetica Neue", Helvetica, Roboto, sans-serif;
}

body {
  font-family: regular, Arial, "Helvetica Neue", Helvetica, Roboto, sans-serif;
  font-size: 18px;
  background: #fff;
  color: #221F1F;
  scroll-behavior: smooth;
}

body.bodyActive{
  overflow: hidden;
}
.pull-left{
  float: left;
}
.pull-right{
  float: right;
}
.clear{
  clear: both;
}
.hide{
  display: none;
}
.show{
  display: block;
}
a:focus, a:hover {
  box-shadow: 0 0.0625rem 0 0 currentColor;
}

.container {
  position: relative;
  width: 96%;
  margin: auto;
}

#mainSection{
  position: relative;
  width: 100%;
}
#mainSection:before{
  content: "";
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    background-image: url(./assets/pattern.png);
    background-repeat: repeat;
    left: 1px;
    width: 6%;
    background-size: auto;
}
#page {
  position: relative;
  width: calc(100% - 6%);
  margin-left: 6%;
  background: #fff;
    
}
// #page::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   display: block;
//   height: 100%;
//   background-image: url(assets/pattern.png);
//   background-repeat: repeat;
//   left: -6.2%;
//   width: 6.2%;
//   background-size: 117px auto;
// }
.section.first-section{
  padding-top: 10px;
}
.section {
  padding: 35px 0px;
  .title{
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-bottom: 5px;
    position: relative;
    font-weight: bold;
  }
  .para{
    line-height: 28px;
    a{cursor: pointer;}
  }
}
.section.text-area{
    h1 {
      font-size: 28px;
      font-family: bold;
      line-height: 44px;
      margin-bottom: 15px;
      color: #0460A9;
  }
  h3 {
    font-family: italic;
    font-weight: normal;
    margin-top: 25px;
    font-size: 17px;
  }
 h1.hilighted {
    margin-top: 20px;
}
  h2 {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
    margin-top: 25px;
  }
   p {
    padding-top: 10px;
    padding-bottom: 10px;
    a{
      color: #0460A9;
    text-decoration: none;
    }
  }
  p.highlet {
    font-family: italic;
    color: #555;
}
  ol {
    position: relative;
    margin-left: 20px;
      li {
        position: relative;
        line-height: 28px;
        padding-top: 10px;
        padding-bottom: 5px;
        list-style: decimal;
    }
  }
  .button-section.terms-buttons {
    margin-top: 20px;
  }
}
.line {
  position: relative;
  width: 96%;
  margin: auto;
  border-top: 2px solid #221f1f;
}

.read-more{
  border: 2px solid #000;
  display: table;
  font-family: medium;
  display: table;
  position: relative;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 10px 20px;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
    position: relative;
    color: inherit;
    text-decoration: none;
}
.read-more:hover {
  background: #000;
  color: #fff;
}


.read-more.external a {
  padding-right: 34px;
  span{
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
    width: 15px;
    height: 15px;
    margin: auto;
    background-image: url(assets/icons/external-link.svg);
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out;
  }
}

.read-more.external:hover a span {
  background-image: url(assets/icons/external-link-w.svg);
  background-size: 14px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.2s ease-in-out;
}

.popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 22;
    .popup-overlay {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background: #090909;
      opacity: 0.9;
  }
    .popup-center {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 100;
      width: 600px;
      height: 315px;
      background: #fff;
      margin: auto;
      border: 1px solid #efefef;
      transform: scale(1.2);
      transition: all 0.3s ease-in-out;
      animation-name: pop-animate;
      animation-delay: 0.1s;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-direction: normal;
      -moz-animation-timing-function: ease-in-out;
        .popup-body {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 15px;
          overflow: hidden;
            .popup-close {
              cursor: pointer;
              width: 15px;
              height: 15px;
              background: url(./assets/icons/close.svg);
              background-size: cover;
              position: absolute;
              right: 15px;
              top: 20px;
          }
            .popup-inner {
              padding: 20px 25px;
                .popup-logo {
                  width: 180px;
                  margin-bottom: 25px;
              }
            
            .popup-para {
              line-height: 28px;
              a, span{
                color:#0460A9;
                text-decoration: none;
                cursor: pointer;
              }
              span:hover{
                text-decoration: underline;
              }
            }
            .popup-btn-sec {
              margin-top: 25px;
              position: relative;
            }
          }

      }
  }
}
.right-gap{
  margin-right: 20px;
}

@keyframes pop-animate {
  from {
      transform: scale(1.2);
  }
  to {
      transform: scale(1);
  }
}





/** select css starts */

.input-item.library-search{
  .css-1fhf3k1-control{    
    overflow: hidden;
  }
}
.css-at12u2-loadingIndicator{
  display: none !important;
}
.css-1xh8qep-loadingIndicator{
  display: none !important;
}
.css-1uccc91-singleValue, .css-1wa3eu0-placeholder{
  font-size: 17px;
}
.css-yk16xz-control {
  min-height: 45px !important;
  border-radius: 0 !important;
  border: 1px solid #dedede !important;
}
.css-1pahdxg-control{
    outline: none;
    min-height: 45px !important;
    border-radius: 0 !important;
    border: 1px solid #221f1f !important;
    box-shadow: none !important;
}
.css-2613qy-menu{
 
}
.item.select-item.input-item {
  .css-yk16xz-control{
    overflow: hidden;
  }
  .css-1pahdxg-control{
    overflow: hidden;
  }
  .css-1hb7zxy-IndicatorsContainer, .css-1wy0on6{
    margin-right: -37px;
  }
  .css-1wa3eu0-placeholder{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 16px);
  }
  // .css-tlfecz-indicatorContainer{
  //    display: none !important;
  // }
  //  .css-1hb7zxy-IndicatorsContainer{
  //    display: none !important;
  //  }
  //  .css-1hb7zxy-IndicatorsContainer{
  //   .css-1gtu0rj-indicatorContainer{
  //     display: none !important;
  //   }
  //  }
}

/** select css ends */


/** survey pop start */

.survey-pop{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.80);
  z-index: 100;
  overflow: auto;
  .google-translate-btn{
    position: fixed;
    right: 2%;
    color: #fff;
    cursor: pointer;
    padding: 8px 0px;
    padding-right: 25px;
    line-height: 16px;
    margin: 18px 0px;
      span{
          position: absolute;
          width: 18px;
          height: 18px;
          background-image: url(./assets/google-translate.svg);
          background-repeat: no-repeat;
          background-size: 18px;
          background-position: center;
          right: 0;
      }
  }
  .center{
      position: relative;
      width: 500px;
      left: 0;
      right: 0;
      top: 70px;
      margin: auto;
      border: 1px solid #e6e6e6;
      background: #fff;
      .survey-title{
          font-family: bold;
          font-size: 20px;
          color: #0460a9;
          position: relative;
          padding-bottom: 11px;
          padding: 15px 40px;
          border-bottom: 1px solid #ccc;
          .fetitle{
              position: relative;
              line-height: 20px;
              word-wrap: break-word;
          }
          p{
            position: relative;
            font-size: 11px;
            width: 100%;
            margin-top: 7px;
            color: #676767;
            line-height: 15px;
          }
      }
      .survey-cont{
          position: relative;
          padding: 35px 40px;
          padding-top: 15px;
          padding-bottom: 30px;
          overflow: hidden;
          min-height: 220px;
          .form{
              position: relative;
              .form-item{
                  position: relative;
                  h1{
                      position: relative;
                      margin-bottom: 6px;
                      line-height: 24px;
                      font-size: 16px;
                      word-wrap: break-word;
                  }
                  
              }
          }
          .buttons-section{
              margin-top: 30px;
              .read-more{
                  margin: auto;
                  display: block;
                  text-align: center;
                  border: 2px solid #0460a9;
                  background: #0460a9;
                  color: #fff;
                  a{
                      color: #fff;
                  }
              }
              .read-more:hover{
                  background: #023761;
                  border: 2px solid #023761;
              }
          }
      }
  }
}
/** survey pop ends */

/** feedback pop start */
.feedback-pop{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.80);
    z-index: 100;
    overflow: auto;
    .google-translate-btn{
        position: fixed;
        right: 2%;
        color: #fff;
        cursor: pointer;
        padding: 8px 0px;
        padding-right: 25px;
        line-height: 16px;
        margin: 18px 0px;
        span{
            position: absolute;
            width: 18px;
            height: 18px;
            background-image: url(./assets/google-translate.svg);
            background-repeat: no-repeat;
            background-size: 18px;
            background-position: center;
            right: 0;
        }
    }
    .center{
        position: relative;
        width: 620px;
        left: 0;
        right: 0;
        top: 70px;
        margin: auto;
        border: 1px solid #e6e6e6;
        background: #fff;
        .feedback-title{          
          position: relative;
          padding-bottom: 11px;
          padding: 15px 30px;
          border-bottom: 1px solid #ccc;
          h1{
            position: relative;
            font-family: bold;
            font-size: 20px;
            color: #0460a9;
            float: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }
          .close{
            position: relative;
            float: right;
            width: 20px;
            height: 20px;
            background-image: url(assets/icons/close.svg);
            background-size:cover;
            background-position: center;
            cursor: pointer;
          }
        }
        .feedback-cont{
          position: relative;
          padding: 35px 30px;
          padding-top: 15px;
          padding-bottom: 30px;
          overflow: hidden;
    
          .para{
            line-height: 24px;
            font-size: 15px;
          }
          .form{
            position: relative;
            margin-top: 10px;
            .form-item{
              position: relative;
              margin-top: 10px;
              h1{
                position: relative;
                margin-bottom: 6px;
                line-height: 24px;
                font-size: 16px;
                word-wrap: break-word;
              }
              .cont.star{
                background: #eee;
              }
              .cont{
                width: 100%;
                text-align: center;
                overflow: hidden;                
                .stars{
                  display: table;
                  margin: auto;
                  .star-icon{
                    float: left;
                    padding: 6px 22px;
                    font-size: 22px;
                    transition: all 0.3s ease-in-out;
                    i{
                      color: #444;
                    }
                  }
                  .star-icon:hover{
                    transform: rotate(-15deg) scale(1.3);
                  }
                  .star-icon.active i{
                    color: #0460a9;
                  }
                }
                .radio-box{
                  .radio-item{
                    float: left;
                    margin-right: 30px;
                  }
                }
                .rating{
                  position: relative;
                  border: 1px solid #000;
                  background: linear-gradient(to right, #0460a9, #8d1f1b, #ec9a1e);
                  .rating-item:nth-of-type(1):before{
                    display: none;
                  }
                  .rating-item:before{
                    content: "";
                    position: absolute;
                    left:0;
                    top:0;
                    bottom: 0;
                    border-left: 1px solid #000;
                  }
                  .rating-item{
                    width: 10%;
                    height: 30px;
                    position: relative;
                    float: left;
                    label{
                      display: block;
                      cursor: pointer;
                      width: 100%;
                      height: 100%;
                      text-align: center;
                      line-height: 30px;
                      transition: all 0.5s ease-in-out;
                      background-color: #fff;
                      color: #000;
                      font-size: 15px;
                    }
                  }
                  .rating-item:hover label{
                    background: transparent;
                    color: #fff;
                  }
                  .rating-item.active {
                    label{
                      background: transparent;
                      color: #fff;
                    }                   
                  }
                }
                .like-text{
                  position: relative;
                  margin-top: 6px;
                  h5{
                    font-size: 12px;
                    color: #a2a2a2;
                    font-family: light;
                    letter-spacing: 1px;
                  }
                }
              }
            }
          }
          .buttons-section{
            margin-top: 30px;
            position: relative;
            .read-more.submit-btn{
              display: block;
              text-align: center;
              border: 2px solid #0460a9;
              background: #0460a9;
              color: #fff;
              float: right;
            }
            .read-more.submit-btn.disabled{
              cursor: not-allowed;
              border: 2px solid #c3c3c3;
              background: #c3c3c3;
              color: #fff;
            }
            .read-more.submit-btn.disabled:hover{
              border: 2px solid #c3c3c3;
              background: #c3c3c3;
            }
            .read-more.cancel-btn{
              display: block;
              text-align: center;
              border: 2px solid #c3c3c3;
              background: #c3c3c3;
              color: #fff;
              float: right;
              margin-right: 20px;
            }
            .read-more.submit-btn:hover{
              background: #023761;
              border: 2px solid #023761;
            }
            .read-more.cancel-btn:hover{
              border: 2px solid #a9a9a9;
              background: #a9a9a9;
            }
          }
        }
    }
}
/** feedback pop ends */

/** radio button starts */
.radio-box{
  position: relative;
   input {
      width: calc(100% - 20px);
      resize: none;
      height: 16px;
      padding: 10px;
      font-size: 15px;
      border: 1px solid #e2e2e2;
      color: #333;
  }
  input:focus{
      outline: none;
      border: 1px solid #0460a9;
  }
  .radio-item{
      display: table;
      position: relative;
      padding-left: 38px;
      margin-bottom: 15px;
      cursor: pointer;
      line-height: 20px;
      font-size: 15px;
      word-break: break-word;
      
      span{
          position: absolute;
          top: 0;
          left: 0;
          height: 16px;
          width: 16px;
          border: 1px solid #cfcfcf;
          border-radius: 100%;
          box-shadow: none;
      }
      span:before {
          position: absolute;                                
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          margin: auto;
          background: #0460a9;
      }
  }
 .radio-item.active span:before {
      content: "";
  }
}
/** radio button ends */



/** loader css starts */

.loaderMsg {
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  left: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
  z-index: 1;

  .msg-center {
    position: absolute;
    width: 90%;
    height: 120px;
    left: 0;
    overflow: hidden;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    .msg-icon {
      width: 38px;
      height: 38px;
      background-size: cover;
      background-repeat: no-repeat;
      margin: auto;
      margin-bottom: 10px;
    }

    span {
      font-family: bold;
      text-align: center;
      margin-top: 5px;
      display: block;
    }
  }
}

.loaderMsg.loader {
  .msg-center {
    height: 50px;

    .msg-icon {
      background-image: url("./assets/icons/load-waiting.gif");
    }
  }
}

.loaderMsg.nodata {
  .msg-center {
    height: 80px;

    .msg-icon {
      background-image: url("./assets/icons/nodata.svg");
    }

    span {
      color: #b9b9b9;
    }
  }
}

.loaderMsg.error {
  .msg-center {
    height: 80px;

    .msg-icon {
      background-image: url("./assets/icons/error-icon.svg");
    }

    span {
      color: #f00;
    }
  }
}

.loaderMsg.success {
  .msg-center {
    height: 100px;

    .msg-icon {
      background-image: url("./assets/icons/success-icon.svg");
    }

    span {
      color: #1fa40d;
    }
  }
}
.minimum-error{
  position: absolute;
  padding: 10px 12px;
  left: 0;
  top: 114%;
  color: #f00;
  background: #fff;
  border: 1px solid #ccc;
  font-size: 15px;
}
.minimum-error::before{
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  transform: rotate(45deg);
  left: 20px;
  top: -5px;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
.productLoader{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5;
  background: #fff;
}

.pagenotfound{
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    min-height: 500px;  
  h1{
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: 30px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
    color: #1F140C;
    line-height: 1.3em;
    font-weight: normal;
  }
  p{
    line-height: 25px;
    color: #1F140C;
  }
  h2{
    line-height: 25px;
    color: #0460a9;
    cursor: pointer;
    margin-top: 20px;
    a{
      text-decoration: none;
      color:#0460a9;
    }
  }
}

body.google{
  .top-header{
    position: fixed;
    right: 2%;
    z-index: 1000;
    width: 190px;
    left: auto;
    top: 65px;
    height: auto;
    padding: 7px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    .google-translate{
      #google_translate_element{

      }
    }
  }
}

.title_tabs{
  position: relative;
  border-bottom: 1px solid #b3b3b3;
  margin: 10px 0px;
  ul{
    position: relative;
    li{
      position: relative;
      float: left;
      padding: 10px 15px;
      margin-right: 10px;
      font-family: bold;
      cursor: pointer;
      border-bottom: 2px solid #fff
    }
    li.disabled{
      cursor: not-allowed;
    }
li.active{
  color: #0460a9;
  /* color: #fff; */
  border-bottom: 2px solid #0460a9;
}
  }
}

@media screen and (max-width:1280px){
  * {
    font-size: 15px;
  }
  
  body {
    font-size: 15px;
  }

  .pagenotfound{
    min-height: 500px;  
  h1{
    padding-top: 45px;
    padding-bottom: 20px;
    font-size: 26px;
  }
}
  .section {
    padding: 30px 0px;
    .title{
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    .para{
      line-height: 25px;
    }
  }


  
.read-more{
 
  a{
    padding: 8px 15px;
  }
}


.read-more.external a {
  padding-right: 30px;
  span{
    right: 10px;
    width: 13px;
    height: 13px;
    background-size: 12px;
  }
}

.read-more.external:hover a span {
  right: 10px;
  width: 13px;
  height: 13px;
  background-size: 12px;
}


.section.text-area{
  h1 {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
}
h3 {
  font-size: 15px;
  margin-top: 20px;
}
h1.hilighted {
  margin-top: 15px;
}
h2 {
  font-size: 18px;
    line-height: 25px;
    margin-bottom: 5px;
    margin-top: 20px;
}
 p {
  line-height: 25px;
  a{
  }
}
p.highlet {
}
ol {
    li {
      line-height: 25px;
    padding-top: 6px;
  }
}
.button-section.terms-buttons {
  
}
}


.feedback-pop{
  .google-translate-btn{
      margin: 14px 0px;
      span{
      }
  }
  .center{
      .feedback-title{
        h1{
        font-size: 16px;
        }
        .close{}
      }
      .feedback-cont{
  
        .para{
            line-height: 22px;
            font-size: 13px;
        
        }
        .form{
          .form-item{
            h1{
              font-size: 15px;
            }
           
            .cont{             
              .stars{
                .star-icon{
                  i{
                  }
                }
              }
              .rating{
                
                .rating-item{
                  height: 28px;
                  label{
                    font-size: 13px;
                  }
                }
              }
              .like-text{
                h5{
                  font-size: 11px;
                }
              }
            }
          }
        }
        .buttons-section{
          .read-more.submit-btn{
          }
          .read-more.cancel-btn{
          }
        }
      }
  }
}


.survey-pop{
  .google-translate-btn{
      margin: 14px 0px;
      span{
      }
  }
  .center{
      .survey-title{
        font-size: 16px;
          .fetitle{
          }
          p{
          }
      }
      .survey-cont{
          .form{
              .form-item{
                  h1{
                    line-height: 20px;
              font-size: 15px;
                  }
                  
              }
          }
          .buttons-section{
              margin-top: 25px;
              .read-more{
              }
          }
      }
  }
}

.radio-box{

   input {
  }

  .radio-item{
      font-size: 13px;
      span{
        width: 14px;
        height: 14px;
      }
      span:before {
      }
  }

}


.popup {

    .popup-center {
      width: 560px;
      height: 300px;
        .popup-body {
            .popup-close {
          }
            .popup-inner {
                .popup-logo {
                  width: 160px;
                  margin-bottom: 20px;
              }
            
            .popup-para {
              line-height: 25px;
              a{
               
              }
            }
            .popup-btn-sec {
             
            }
          }

      }
  }
}


.css-1uccc91-singleValue, .css-1wa3eu0-placeholder{
  font-size: 13px;
}
.css-yk16xz-control {
  min-height: 40px !important;
}
.css-1pahdxg-control{
    outline: none;
    min-height: 40px !important;
}

.minimum-error{
  padding: 8px 12px;
  font-size: 12px;
}



}

@media screen and (max-width:1000px){
  * {
    font-size: 14px;
  }
  
  body {
    font-size: 14px;
  }

  .section {
    padding: 25px 0px;
    .title{
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 12px;
    }
    .para{
      line-height: 22px;
    }
  }

  .section.text-area{
    h1 {
      font-size: 22px;
      line-height: 40px;
  }
  h3 {
    font-size: 16px;
  }
 h1.hilighted {
    margin-top: 20px;
}
  h2 {
    font-size: 17px;
    margin-top: 15px;
  }
   p {
    a{
    }
  }
  p.highlet {
}
  ol {
      li {
    }
  }
  .button-section.terms-buttons {
  
  }
}



.popup {

  .popup-center {
      .popup-body {
          .popup-close {
        }
          .popup-inner {
              .popup-logo {
                width: 150px;
            }
          
          .popup-para {
            line-height: 22px;
            a{
             
            }
          }
          .popup-btn-sec {
           
          }
        }

    }
}
}



}

@media screen and (max-width:768px){
  .section.text-area{
    h1 {
      font-size: 20px;
      line-height: 26px;
  }
}
  .pagenotfound{
    min-height: 500px;  
  h1{
    padding-top: 40px;
    padding-bottom: 15px;
    font-size: 24px;
  }
}

  #mainSection:before{
    background-size: 105px;
  }
  .top-header .google-translate.survey-google {
    position: fixed;
    top: 14px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  }


  

.feedback-pop{
  .google-translate-btn{
      margin: 21px 0px;
      span{
      }
  }
  .center{
    width: 96%;
      .feedback-title{
        padding: 15px 20px;
        h1{
          font-size: 15px;
          }
          .close{
            width: 15px;
            height: 15px;
          }
      }
      .feedback-cont{
        padding: 15px 20px;
  
        .para{
            line-height: 22px;
            font-size: 13px;
        
        }
        .form{
          .form-item{
            h1{
              line-height: 20px;
              font-size: 15px;
            }
           
            .cont{             
              .stars{
                .star-icon{
                  i{
                  }
                }
              }
              .rating{
                
                .rating-item{
                  height: 28px;
                  label{
                    font-size: 13px;
                  }
                }
              }
              .like-text{
                h5{
                  font-size: 11px;
                }
              }
            }
          }
        }
        .buttons-section{
          .read-more.submit-btn{
          }
          .read-more.cancel-btn{
          }
        }
      }
  }
}

.survey-pop{

  .google-translate-btn{
      span{
      }
  }
  .center{
      .survey-title{
         
          p{
          }
      }
      .survey-cont{
          .form{
              .form-item{
                  h1{
                      font-size: 15px;
                  }
                  
              }
          }
      }
  }
}

.search-form.library .item.search-item {
  margin-left: 0px;
  width: calc(100% - 65px);
  margin-top: 15px;
}
}

@media screen and (max-width:650px){
  .pagenotfound{
    min-height: 450px;  
  h1{
    padding-top: 30px;
    padding-bottom: 15px;
    font-size: 20px;
  }
}
  #mainSection:before{
    background-size: 75px;
  }
  .section {
    padding: 20px 0px;
    .title{
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .para{
      
    }
  }

  .popup {

    .popup-center {
      width: 96%;
        .popup-body {
            .popup-close {
          }
            .popup-inner {
              padding: 15px;
                .popup-logo {
                  width: 150px;
              }
            
            .popup-para {
              line-height: 20px;
              a{
               
              }
            }
            .popup-btn-sec {
             
            }
          }
  
      }
  }
  }

.section.text-area p {
  padding-top: 6px;
  padding-bottom: 6px;
}

  .section.text-area{
    h1 {
      font-size: 18px;
      line-height: 30px;
  }
  h3 {
    font-size: 15px;
  }
 h1.hilighted {
    margin-top: 20px;
}
  h2 {
    font-size: 16px;
    margin-top: 15px;
  }
   p {
    a{
    }
  }

}
.left-section .results-div .noresultbox {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.read-more{
  padding: 8px 15px;
}
.header .bottom-header .container .logo {
  width: 140px;
}
.section.text-area p {
  line-height: 21px;
}


.feedback-pop{
  .center{
      .feedback-cont{
        padding: 15px 20px;
  
       
        .form{
          .form-item{
            h1{
              font-size: 14px;
            }
           
            .cont{             
              .stars{
                .star-icon{
                  i{
                  }
                }
              }
            }
          }
        }
      }
  }
}

.survey-pop{

  .google-translate-btn{
      span{
      }
  }
  .center{
    width: 96%;
      .survey-title{
        padding: 15px 20px;
          p{
          }
      }
      .survey-cont{
        position: relative;
        padding: 30px 20px;
        padding-top: 15px;
          .form{
              .form-item{
                  h1{
                      font-size: 14px;
                  }
                  
              }
          }
      }
  }
}


  
}

@media screen and (max-width:500px){
 
.css-1uccc91-singleValue, .css-1wa3eu0-placeholder{
  font-size: 12px;
}


.css-yk16xz-control {
  min-height: 36px !important;
}
.css-1pahdxg-control{
    outline: none;
    min-height: 36px !important;
}


}

@media screen and (max-width:400px){
  .read-more {
    padding: 8px 10px;
}

  .feedback-pop .center .feedback-title h1 {
    font-size: 14px;
}

  .pagenotfound{
    min-height: 400px;  
  h1{
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 18px;
  }
}

  #mainSection:before{
    background-size: 58px;
  }
  * {
    font-size: 13px;
  }
  
  body {
    font-size: 13px;
  }
  .top-header .google-translate.survey-google {
    width: 185px;
  }

  .section.text-area h1 {
    font-size: 17px;
    line-height: 26px;
}
.section.text-area h1.hilighted {
  margin-top: 15px;
  margin-bottom: 6px;
} 


.feedback-pop{
  .center{
      .feedback-cont{
  
       
        .form{
          .form-item{
            h1{
              font-size: 13px;
            }
           
          }
        }
      }
  }
}

.survey-pop{

  .google-translate-btn{
      span{
      }
  }
  .center{
      .survey-title{
          p{
          }
      }
      .survey-cont{
          .form{
              .form-item{
                  h1{
                      font-size: 13px;
                  }
                  
              }
          }
      }
  }
}
.feedback-pop .center .feedback-cont .form .form-item .cont .stars .star-icon {
  padding: 6px 16px;
}


.search-form {
  padding: 10px 0px;
  background: #fff;
}
.popup .popup-center {
  height: 260px;
}
.popup .popup-center .popup-body .popup-inner {
  padding: 5px;
}

}

@media screen and (max-width:350px){
  .feedback-pop .center .feedback-cont .form .form-item .cont .stars .star-icon {
    padding: 6px 12px;
  }
  
}