
.track-horizontal ~ div div{
    background-color: #fff !important;
}
.banner {
    position: relative;
    width: 100%;
    height: calc(100vh - 67px);
    z-index: unset;
    .banner-inner{
        position: absolute;
        left: 0;
        right: 0;
        top: 0px;
        bottom: 0;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        background-image: url(../../assets/home-banner.jpg);
        
    }
    .banner-patch.patch-scroll{
        width: 40%;
        height: 300px;
        padding: 0;
        .banner-patch-center{
            padding: 30px;
        }
    }
    .banner-patch{
        max-width: 500px;
        padding: 50px;
        margin-bottom: 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        color: #FFFFFF;
        background-color: #0460A9;
        .banner-title{
            margin-bottom: 20px;
            font-size: 30px;
            font-family: medium;
            line-height: 36px;
        }
        .text{
            line-height: 28px;
            margin: 10px 0px;
        }
    }
}
.section{
    .img-text-box{
        position: relative;
        .img-text-item:nth-of-type(1){
            margin-top: 0;
        }
        .img-text-item{
            position: relative;
            margin-top: 50px;
            .img-text-left{
                width: 48%;
            }
            .img-text-right{
                width: 48%;
            }
            img{
                width: 100%;
            }
            .title{}
            .para{}
            .read-more{
                margin-top: 20px;
            }
        }
    }
    .three-box{
        position: relative;
        .three-box-item{
            position: relative;
            width: calc(33.33% - 0%);
            box-sizing: border-box;
            padding: 0px 20px;
            border-left: 1px dashed #e3e3e3;
            border-left: 1px dashed #d1d1d1;
            min-height: 283px;
            
            .title{}
            .para{ }
            
            .read-more{
                position: absolute;
                bottom: 20px;
                left: 20px;
            }
            .video-img{
                position: relative;
                width: 100%;
                height: 205px;
                background-image: url(../../assets/images/video-img.jpg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                .play-icon{
                    width: 40px;
                    height: 40px;
                    border: 2px solid #000;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    line-height: 40px;
                    cursor: pointer;
                    background-size: 20px;
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: all 0.3s ease-in-out;
                    background-color: #000;
                    background-image: url(../../assets/icons/play-icon-w.svg);
                }
            }
        }
        .three-box-item.one, .three-box-item.four{
            border-left: none;
            padding-left: 0px;
            .read-more{
                left: 0;
            }
        }
        .three-box-item.four, .three-box-item.five, .three-box-item.six{
            border-top: 1px dashed #d1d1d1;
            padding-top: 20px;
            min-height: 300px;
        }
    }
}

.video-pop{
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    outline: none;
    background-color: rgba(255, 255, 255, .9);
    opacity: 1;
    .video-close{
        width: 40px;
        height: 40px;
        position: absolute;
        right: 1%;
        top: 4%;
        text-align: center;
        padding: 0;
        font-size: 35px;
        line-height: 40px;
        color: #221f1f;
        cursor: pointer;
    }
    .video-close:hover {
        color: #fff;
        background-color: #221f1f;
    }
    .video-cont{
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 90%;
        height: 84%;
        margin: auto;
        transition: all 0.3s ease-in-out;
        background: #fff;
        transition: all 1s ease-in-out;
        animation-name: animate;
        animation-duration: 0.4s;
        animation-delay: 0;
        animation-direction: normal;
        animation-iteration-count: inherit;
        animation-timing-function: ease-in-out;
        iframe{
            width: 100%;
            height: 100%;
        }
        video{
            width: 100%;
            height: 100%;
        }
    }
}
@keyframes animate {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}

.device-banner-patch{
    display: block;
    padding: 40px;
    position: relative;
    color: #FFFFFF;
    background-color: #0460A9;
    display: none;
    .banner-patch{
        .banner-title {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 12px;
            font-family: bold;
        }
        .text {
            line-height: 22px;
            margin: 10px 0px;
        }
    }

}


@media screen and (max-width:1280px){
    .banner {
        .banner-inner{}
        .banner-patch{
            padding: 40px;
            .banner-title{
                margin-bottom: 18px;
                font-size: 25px;
                line-height: 34px;
            }
            .text{
                line-height: 25px;
            }
        }
    }
    .section{
        .img-text-box{
           
            .img-text-item{
               
                .img-text-left{
                   
                }
                .img-text-right{
                    
                }
                img{
                   
                }
                .title{}
                .para{}
                .read-more{
                   
                }
            }
        }
        .three-box{
           
            .three-box-item{
               min-height: 240px;
                
                .title{}
                .para{ }
                
                .read-more{
                   
                }
                .video-img{
                    height: 172px;
                    .play-icon{
                       
                    }
                }
            }
            .three-box-item.one, .three-box-item.four{
                
                .read-more{
                   
                }
            }
            .three-box-item.four, .three-box-item.five, .three-box-item.six{
                min-height: 270px;
            }
        }
    }
    
    .video-pop{
        
        .video-close{
           
        }
        .video-cont{
            
        }
    }
    
    
}

@media screen and (max-width:1024px){

    .banner{
        height: 550px;
    }
    .banner .banner-patch.patch-scroll {
        width: 60%;
        height: 280px;
        padding: 0;
    }
    .banner .banner-patch .banner-title {
        margin-bottom: 18px;
        font-size: 23px;
        line-height: 32px;
    }
}

@media screen and (max-width:1000px){
    .device-banner-patch{
        display: block;
    }
    .banner .banner-patch{
        display: none;
    }
    

    .section{
        .img-text-box{
            .img-text-item{
                .img-text-left.device-show{
                    display: block;
                }
                .img-text-left.device-hide{
                    display: none;
                }
                .img-text-left{
                    float: none;
                    width: 100%;
                    margin-bottom: 15px;
                }
                .img-text-right{
                    float: none;
                    width: 100%;
                }
                .title{}
                .para{}
                .read-more{
                }
            }
        }
        .three-box{
            .three-box-item{
                width: calc(50% - 0%);
                .title{}
                .para{ }
                
                .read-more{
                }
                .video-img{
                        height: 150px;
                }
            }
            .three-box-item.one, .three-box-item.two{
                min-height: 212px;
            }
            .three-box-item.three, .three-box-item.five{
                border-left: none;
                border-top: 1px dashed #d1d1d1;
                padding-top: 20px;
                padding-left: 0;
                min-height: 240px;
                .read-more{
                    left: 0;
                }
            }
            .three-box-item.four{
                border-left: 1px dashed #d1d1d1;
                padding-left: 20px;
                min-height: 240px;
                .read-more{
                    left: 20px;
                }
            }
            .three-box-item.five, .three-box-item.six{
                min-height: 250px;
               
            }
        }
    }

    
}

@media screen and (max-width:768px){

    .device-banner-patch {
        padding: 30px;
    }
    .banner{
        height: 400px;
    }
    .video-pop .video-close {
        width: 35px;
        height: 35px;
        right: 0%;
        line-height: 35px;    
        font-size: 30px;    
    }
    .video-pop .video-cont {
        width: 86%;
        height: 80%;
    }
}

@media screen and (max-width: 650px){
    .section .img-text-box .img-text-item {
        margin-top: 30px;
    }
    .device-banner-patch .banner-patch .banner-title {
        
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
    }
    .device-banner-patch .banner-patch .text {
        line-height: 20px;
    }
    .banner{
        height: 350px;
        
    }
    .device-banner-patch {
        padding:25px 20px;
    }
    .section{
       
        .three-box{
            .three-box-item.one{
                padding-top: 0;
            }
            .three-box-item{
                float: none;
                width: 100%;
                padding: 15px 0px;
                min-height: 10px !important;
                border-left: none !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
                .title{}
                .para{ }
                
                .read-more{
                    position: relative;
                    left: 0 !important;
                    margin-top: 15px;
                    bottom: auto;
                }
                .video-img{
                }
            }
        }
    }

}

@media screen and (max-width:500px){
    .banner{
        height: 300px;
    }
    
}

@media screen and (max-width:400px){
    .banner{
        height: 250px;
    }
    .device-banner-patch {
        padding: 25px 15px;
    }
   
}