.footer {
    position: relative;
    border-top: 1px solid #e2e2e2;
    background: #fff;
    .container {
        padding: 0px 20px;
        padding-left: 8%;
        padding-right: 2%;
        width: calc(100% - 10%);
        .footer1 {
            position: relative;
            p{
            }
        }
        .footer2 {
            position: relative;
            padding-top: 25px;
            .copyright {
                position: relative;
            }
            .foo-center{
                position: relative;
                float: left;
                margin-left: 15%;
            }
            ul {
                position: relative;
               
                 li {
                    float: left;
                    padding: 0;
                    margin: 0;
                    border-bottom: .0625rem solid transparent;
                    transition-property: border-color;
                    transition-duration: 200ms;
                    transition-timing-function: ease-out;
                    cursor: pointer;
                    a, p {
                        padding: 0;
                        margin: 0;
                        border-bottom: .0625rem solid transparent;
                        transition-property: border-color;
                        transition-duration: 200ms;
                        transition-timing-function: ease-out;
                        text-decoration: none;
                        color: inherit;
                        
                    }
                    p:focus, p:hover {
                        box-shadow: 0 0.0625rem 0 0 currentColor;
                    }
                    
                }
               .line-hor:after {
                    content: '|';
                    display: block;
                    float: left;
                    padding: 0 8px;
                    cursor: default;
                }
                li:first-child:after{
                    display: none;
                }
            }
        }
    }
}
@media screen and (max-width:1100px){
    .footer .container .footer2 .foo-center {
        margin-left: 12%;
    }
}

@media screen and (max-width:1024px){
    .footer .container .footer2 .foo-center {
        margin-left: 9%;
    }
}

@media screen and (max-width:1000px){
    .footer .container .footer2 .copyright {
        width: 30%;
    }
    .footer .container .footer2 .foo-center {
        width: 70%;
        margin: 0;
        text-align: right;
    }
    .footer .container .footer2 ul {
        float: left;
        margin-top: 20px;
    }
}

@media screen and (max-width:650px){
    .footer .container .footer2 {
        padding-top: 20px;
    }
}

@media screen and (max-width:500px){
    .footer .container .footer2 .copyright {
        width: 100%;
        float: none;
    }
    .footer .container .footer2 .foo-center {
        width: 100%;
        float: none;
        text-align: left;
        margin-top: 20px;
    }
    .footer .container .footer2 ul {
        width: 100%;
        float: none;
    }
}