

/** inner pages starts*/
.results_content{
  position: relative;
}
.results_content.fixed{
  padding-top: 90px;
}

.title_gap{
  margin-bottom: 25px;
}
.search-form.fixed{
  position: fixed;
  top: 61px;
  width: calc(58% - 2px);
  left: calc(8% - 2px);
  z-index: 8 !important;
}
.search-form.fixed.google_fixed{
  top: 114px;
}
.search-form.library{
  .category-item{}
  .item.search-item{
    margin-left: 25px;
    width: calc(64% - 50px);
  }
}
.search-form.high{
  z-index: 2;
}
.search-form {
  position: relative;
  width: 100%;
  background-color: #efefef;
  padding: 25px;
  box-sizing: border-box;

  .css-1fhf3k1-control {
    background-color: #cccccc;
    border-radius: 0;
    height: 45px;
    .css-1hb7zxy-IndicatorsContainer{
      margin-right: 0 !important;
    }
  }
  .item {
    position: relative;
}
.item.select-item{
  width: calc(36% - 50px);
  float: left;
}
.item.select-item.product{
  width:16%;
  float: left;
  margin-left: 25px;
}
.item.select-item.input-item.product-search{
  width: calc(44% - 50px);
}
.item.input-item{
  width: calc(64% - 50px);
  float: left;
  margin-left: 25px;
  .css-109onse-indicatorSeparator{
    display: none;
  }
  .input-input {
    position: relative;
    width: calc(100% - 0px);
    height: 45px;
    border: 1px solid #dedede;
    padding: 0px 10px;
    box-sizing: border-box;
    font-size: 16px;
    background-color: #fff;
}
.input-input:focus{
  outline: none;
  box-shadow: none;
  border: 1px solid #221f1f;
}
.input-dropdown{
  position: absolute;
  top: 45px;
  left:0;
  width: 100%;
  overflow: auto;
  box-shadow: 2px 2px 20px rgba(0,0,0,0.2);
  min-height: 30px;
  max-height: 350px;
  background-color: #fff;
  border: 1px solid #ddd;
  ul{
    li{
      color: rgb(51, 51, 51);
      background-color: rgb(253, 253, 253);
      border-bottom: 1px solid #ddd;
      padding: 10px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
    li:hover{
      background: #0460A9;
      color: #fff;
    }
  }
}
}
.item.icon-item{
  width: 50px;
  float: right;
  .icon {
    width: 50px;
    height: 45px;
    background: #221F1F;
    border: 1px solid #221F1F;
    cursor: pointer;
    float: left;
    box-sizing: border-box;
    background-image: url(./../../assets/icons/search-icon-w.svg);
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out;
}
.icon:hover {
  background: #fff;
  background-image: url(./../../assets/icons/search-icon.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
}
}
}
.search-form.one-select{
.select-item{
  width: 100%;
  float: left;
}
}

.breadcrumb.outside{
  border-top: 1px solid #e2e2e2;
  border-bottom: none;
  .container{
    padding: 0px 20px;
    padding-left: 8%;
    padding-right: 2%;
    width: calc(100% - 10%);
  }
}
.breadcrumb{
  position: relative;
    border-bottom: 1px solid #e2e2e2;
    ol{
      padding: 18px 0;
      li {
        position: relative;
        float: left;
        padding: 5px 0;
          line-height: 15px;
          display: inline-block;
          font-family: bold;
         span {
          margin: 0px 12px;
          font-size: 20px;
          display: inline-block;
      }
        a {
          color: inherit;
          text-decoration: none;
          
        }
      }
      li.active {
        cursor: default;
        a{
          font-family: medium;
          color: #717171;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        a:hover{
          box-shadow: none;
        }
    }
    }
}
.title-item{
  margin-top: 30px;
  .title{
    color: #0460A9;
    font-size: 28px;
    margin-bottom: 25px;
  }
}
.left-section{
  position: relative;
  width: 64%;
  box-sizing: border-box;
  
.alphabets{
  position: relative;
  margin-top: 20px;
  h1{}
  ul{
    padding: 18px 0px;
    margin-left: -2px;
    margin-right: -2px;
    li{
      padding: 10px 0px;
      text-align: center;
      margin: 0px 1px;
      cursor: pointer;
      float: left;
      box-sizing: border-box;
      width: calc(3.84% - 2px);
    }
    li.active, li:hover{
      background: #ddd;
      text-decoration: none;
    }
  }
}

.results-div{
  position: relative;
  margin-top: 25px;
  min-height: 350px;
  
  .accordion-box{
    position: relative;
    .product_main_title{
      position: relative;
      font-size: 19px;
      color: #0460a9;
      font-weight: bold;
      padding-bottom: 10px;
    }
    .accordion-item{
      position: relative;
      border-bottom: 1px solid #dadada;
      .accordion-head{
        padding: 15px 0px;
        padding-right: 30px;
        padding-left: 0;
        position: relative;
        font-size: 20px;
        cursor: pointer;
        .accordion-title{
          font-size: 19px;
          font-weight: 600;
          word-wrap: break-word;
          line-height: 20px;
        }
      }
       .accordion-head:before {
          content: '+';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 22px;
          height: 22px;
          font-weight: 300;
          text-align: center;
          margin: auto;
          font-size: 22px;
          cursor: pointer;
      }
      .accordion-body{
        position: relative;
        .product_title {
          position: relative;
          font-size: 17px;
          color: #0460a9;
          font-weight: bold;
          padding-top: 5px;
        }
        
        ul{
          display: block;
          margin: 15px 30px;
          line-height: 24px;
          li{
            padding-left: 16px;
            margin-bottom: 12px;
            position: relative;
            a, p{
              cursor: pointer;
              word-wrap: break-word;
            }
          }
          li::before {
            content: "";
            position: absolute;
            left: 0;
            width: 4px;
            height: 4px;
            background: #000;
            top: 0;
            bottom: 0;
            border-radius: 100%;
            margin: auto;
        }
        }
        .study-box.product-study-box{
          min-height: 120px;
        }
        .study-box{
          position: relative;
          margin-bottom: 10px;
          .study-box-inner{
            position: relative;
            .study-item{
              position: relative;
              width: 100%;
              background: #fff;
              border-top: 1px dashed #d6d6d6;
              box-sizing: border-box;
              list-style: none;
              padding: 0px 10px;
              p:nth-of-type(1) {
                color: #0460A9;
                font-weight: 600;
                width: calc(40% - 0px);
            }
              p{
                position: relative;
                width: calc(60% - 0px);
                float: left;
                padding: 10px 5px;
                box-sizing: border-box;
                line-height: 25px;
                word-wrap: break-word;
                a, span{
                  font-weight: 600;
                  color: #0460A9;
                  cursor: pointer;
                }
                
              }
              p.pdf{
                padding-left: 28px;
                position: relative;
                a, span{
                  color: #0460A9;
                  text-decoration: none;
                }
              }
              p.pdf:before {
                  content: "";
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  background-image: url(./../../assets/icons/pdf-c.svg);
                  background-size: cover;
                  top: 0;
                  left: 3px;
                  bottom: 0;
                  margin: auto;
              }
              .study-item-select.top{
                .search-select{
                  z-index: 3;
                  .select-box.css-2b097c-container{
                    z-index: 3;
                  }
                }
              }
              .study-item-select{
                position: relative;
                width: calc(60% - 5px);
                float: left;
                padding: 10px 0px;
                box-sizing: border-box;
                line-height: 30px;
                line-height: 24px;
                margin-left: 5px;
                .minimum-error{
                  top: 100%;
                  line-height: 12px;
                }
                .search-select{
                  width: calc(100% - 40px);
                  font-size:12px;
                  z-index: 2;
                  .select-box{
                    min-height: 34px;
                    height: 34px;
                    font-size:12px;
                    .css-1wa3eu0-placeholder{
                      font-size: 12px;
                    }

                    
                    .css-yk16xz-control {
                      min-height: 34px !important;
                      font-size:12px;
                    }
                    .css-1pahdxg-control{
                        outline: none;
                        min-height: 34px !important;
                        height:34px;
                        font-size:12px;
                    }

                    .css-tlfecz-indicatorContainer{
                      padding: 5px;
                    }
                    .css-1gtu0rj-indicatorContainer{
                      padding: 5px;
                    }
                    .css-yk16xz-control{
                      min-height: 34px !important;
                      height: 34px;
                      font-size:12px;
                      .css-g1d714-ValueContainer{
                        font-size:12px;

                      }
                    }
                    .css-1hb7zxy-IndicatorsContainer{
                      height: 34px;
                    }
                  }
                }
                .icon{
                  width: 40px;
                  height: 34px;
                  background-image: url(./../../assets//icons//go-icon-w.svg);
                  background-size: 18px;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-color: #221F1F;
                  border: 1px solid #221F1F;
                  cursor: pointer;
                  float: left;
                  box-sizing: border-box;
                  transition: all 0.2s ease-in-out;
                }
                .icon:hover{
                  background-color: #fff;
                  background-image: url(./../../assets/icons/go-icon.svg);
                  background-size: 18px;
                  background-position: center;
                  background-repeat: no-repeat;
                }

               
              }
              .video-icon{
                position: relative;
                width: 34px;
                height: 34px;
                float: left;
                //background-color: #000;
                margin: 10px;
                float: left;
                margin-left: 20px;
                cursor: pointer;
                background-image: url(../../assets/video_file.png);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
              }

              .video_box{
                position: relative;
                width: 100%;
                height: 300px;
                border: 1px solid #000;
                box-sizing: border-box;
                #videoBox{
                  position: relative;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  
                  iframe{
                    width: 100%;
                    height: 100%
                  }
                }
              }

            }
          }
        }
        .study-box:before {
          content: "";
          position: absolute;
          left: 32%;
          width: 1px;
          height: 100%;
          z-index: 1;
          top: 0;
        }
        .inner-item{
          position: relative;
          border-top: 1px dashed #d4d4d4;
          padding: 0px 20px;
          .inner-head{
            padding-right: 30px;
            padding-left: 0;
            position: relative;
            font-size: 20px;
            cursor: pointer;
            padding: 10px 0px;
            word-wrap: break-word;
            line-height: 20px;
            padding-right: 25px;
            .inner-title{
              position: relative;
            }
            .inner-body{
              position: relative;
            }
          }
         
           .inner-head:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 20px;
            height: 20px;
            font-weight: 300;
            text-align: center;
            margin: auto;
            font-size: 22px;
            cursor: pointer;
            background-image: url(./../../assets/icons/select.arrow.png);
            background-size: 18px;
            background-repeat: no-repeat;
            transform: rotate(-90deg);
        }
        }
        .inner-item.active{
          .inner-head:before{
             transform: rotate(0deg);
          }
        }
      }
    }
    
    .accordion-item.active{
      .accordion-head:before {
        content: "-";
      }
    }
  }

  .noresultbox{
    position: relative;
    width: 400px;
    padding: 10px;
    background-color: #fff;
    text-align: center;
    margin: auto;
    margin-top: 80px;
    .noresultbox-inner{
      position: relative;
      .icon{
        width: 50px;
        height: 50px;
        background-image: url(./../../assets/icons/noresults.svg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: auto;
        margin-bottom: 20px;
      }
      p{
        line-height: 25px;
        text-align: center;
        color: #0460A9;
      }
    }
  }
  .display-results{
    font-size: 17px;
    line-height: 30px;
    margin: 25px 0px;

  }
  .indication-title{
    padding: 12px 0;
    font-size: 17px;
    font-weight: bold;
    border-bottom: 0.25rem solid #221F1F;
  }
}

}
.right-section{
  width: 26%;
  box-sizing: border-box;
 
    .feedBack-box {
      position: relative;
      overflow: hidden;
      width: calc(100% - 0px);
      margin-top: 30px;
      position: relative;
      background: #ec9a1e;
      .center {
        position: relative;
        padding:20px;
        .feedBack-icon {
          font-size: 54px;
          color: #0460A9;
          width: 60px;
          height: 70px;
          background-image: url(./../../assets/icons/Feedback-icon.png);
          background-size: 52px;
          background-position: center;
          background-repeat: no-repeat;
          float: left;
          margin-left: -2px;
          margin-top: -4px;
      }
      .feedBack-box-right.text{
        width: 100%;
        p{
          margin: 0;
          padding: 0;
          font-size: 13px;
        }
      }
        .feedBack-box-right {
          line-height: 25px;
          position: relative;
          float: right;
          width: calc(100% - 85px);
          p {
            color: #000;
            line-height: 21px;
            margin-top: 5px;
            text-align: left;
            font-family: bold;
        }
        .read-more {
          margin-top: 15px;
          border: 2px solid #000;
          background: transparent;
          padding: 6px 18px;
          color: #000;
          font-weight: normal;
          font-family: regular;
          p{
            font-family: bold;
            margin: 0;
            padding: 0;
          }
      }
      .read-more:hover {
        background: #000;      
        color: #ec9a1e;
      
    }
    .read-more:hover p{
      color: #ec9a1e;
    }
      }
    }
    .carousel-root{
      height: 100%;
      .carousel.carousel-slider{
        height: 100%;
        .control-dots{
          margin-bottom: 4px;
        }
      }
    }
  }

 
  .related-sec.fixed{
    position: fixed;
    top: 60px;
    width: 23.1%;
  }
  .related-sec.translate-fixed{
    position: fixed;
    top: 114px;
    width: 23.1%;
  }
  .related-sec.absolute{
    position: absolute;
    bottom: 0px;
  }
  .related-sec {
    position: relative;
    margin-top: 30px;
    .line{
      width: 100%;
    }
    .related-title {
      margin: 0;
      padding: 20px 0px;
      font-size: 24px;
      line-height: 30px;
  }
  .related-content {
    position: relative;
    ul {
      position: relative;
       li {
        padding: 7px 0;
        line-height: 20px;
        position: relative;

        p:focus, p:hover {
          box-shadow: 0 0.0625rem 0 0 currentColor;
      }
         a, p {
          transition: all .2s ease-in-out;
          display: table;
          position: relative;
          padding-right: 31px;
          font-family: medium;
          line-height: 24px;
          text-decoration: none;
          color: inherit;
          cursor: pointer;
          span {
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            width: 12px;
            height: 12px;
            margin: auto;
            background-image: url(./../../assets/icons/external-link.svg);
            background-size: 11px;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.3s ease-in-out;
        }
      }
         a {
          transition: all .2s ease-in-out;
          display: table;
          position: relative;
          padding-right: 31px;
          font-family: medium;
          line-height: 24px;
          text-decoration: none;
          color: inherit;
          cursor: pointer;
          span {
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            width: 12px;
            height: 12px;
            margin: auto;
            background-image: url(./../../assets/icons/external-link.svg);
            background-size: 11px;
            background-position: center;
            background-repeat: no-repeat;
            transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
}
}
/** inner pages ends*/

@media screen and (max-width:1500px){
  .right-section .feedBack-box .center {
    padding: 15px;
  }

  .right-section .feedBack-box .center .feedBack-box-right {
    width: calc(100% - 75px);
}
}

@media screen and (max-width:1280px){
  .left-section .results-div .accordion-box .accordion-item .accordion-body .product_title{
    font-size: 14px;
  }
  .left-section .results-div .accordion-box .product_main_title{
    font-size: 16px;
  }
  .title-item{
    margin-top: 25px;
    .title{
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  
    .search-form {
      .css-1fhf3k1-control {
        height: 40px;
      }
      .item {
       
    }
    .item.select-item{
      
    }
    .item.input-item{
      .input-input {
        height: 40px;
        font-size: 14px;
    }
    }
    .item.icon-item{
      .icon {
        width: 50px;
        height: 40px;
    }
    }
  
  .search-form.one-select{
    .select-item{
     
    }
  }
  
  .results-div{
    .accordion-box{
      .accordion-item{
        .accordion-head{
         
          .accordion-title{
            font-size: 16px;
          }
        }
         .accordion-head:before {
            
        }
        .accordion-body{
         
          ul{
            margin: 10px 20px;
            li{
            }
            li::before {
          }
          }
          .study-box{
          
            .study-box-inner{
            
              .study-item{
                p:nth-of-type(1) {
              }
                p{
                  line-height: 20px;
                  padding: 8px 5px;
                }
                p.pdf{
                  a{
                  }
                }
                p.pdf:before {
                }
              }
            }
          }
          .study-box:before {
           
          }
        }
      }
      
    }
  
    .noresultbox{
      .noresultbox-inner{
        
        .icon{
          width: 45px;
          height: 45px;
        }
        p{
        }
      }
    }
    .display-results{
      font-size: 14px;
    line-height: 25px;
    margin: 20px 0px;
  
    }
    .indication-title{
      font-size: 14px;
    }
  }
  
  }

  .right-section{
      .feedBack-box {
        .center {
          .feedBack-icon {
            width: 56px;
            height: 60px;
            background-size: 48px;
        }
          .feedBack-box-right {
            
            p {
              line-height: 19px;
          }
          .read-more {
           
            a {
              padding: 4px 17px;
              font-size: 14px;
          }
        }
        }
      }
    }
    .related-sec {
      
      .line{
      }
      .related-title {
        padding: 20px 0px;
        font-size: 21px;
        line-height: 27px;
    }
    .related-content {
      
      ul {
       
         li {
           padding: 5px 0px;
           a {
            line-height: 22px;
            span {
             
          }
        }
      }
    }
  }
  }
  }

  .right-section .feedBack-box .center {
    padding-bottom: 20px;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 12px;
  }

  .right-section .feedBack-box .center .feedBack-box-right {
    width: calc(100% - 68px);
}

}

@media screen and (max-width:1024px){
  .search-form {
    padding: 20px 15px;
  }
  .left-section {
    position: relative;
    width: 68%;
  }
  .right-section{
    width: 28%;
  }
  .left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item p:nth-of-type(1) {
    
    width: calc(40% - 0px);
}


.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item p {
    width: calc(60% - 0px);
}

 .search-form .item.select-item.product {
  width: calc(22% - 50px);
}

 .search-form .item.select-item.input-item.product-search {
  width: calc(46% - 50px);
}

.search-form.fixed {
  width: calc(62% - 2px);
}

.left-section .results-div .accordion-box .accordion-item .accordion-body ul {
  margin: 10px 25px;
  line-height: 20px;
}

.right-section .related-sec.fixed {
  width: 25.1%;
}
.right-section .related-sec.translate-fixed{
  width: 25.1%;
}

}

@media screen and (max-width:1000px){
  
.search-form .item.select-item.product {
  width: calc(20% - 50px);
}
  .title-item{
    .title{
      font-size: 22px;
      margin-bottom: 15px;
    }
  }

  .left-section{
    float: none;
    width: 100%;
    .search-form {
      .item {
       
    }
    .item.select-item{
      
    }
    .item.input-item{
      .input-input {
    }
    }
    .item.icon-item{
      .icon {
    }
    }
  }
  .search-form.one-select{
    .select-item{
     
    }
  }
  
  .results-div{
    .accordion-box{
      .accordion-item{
        .accordion-head{
         
          .accordion-title{
            font-size: 15px;
          }
        }
         .accordion-head:before {
            
        }
        .accordion-body{
         
          ul{
            li{
            }
            li::before {
          }
          }
          .study-box{
          
            .study-box-inner{
            
              .study-item{
                p:nth-of-type(1) {
              }
                p{
                }
                p.pdf{
                  a{
                  }
                }
                p.pdf:before {
                }
              }
            }
          }
          .study-box:before {
           
          }
        }
      }
      
    }
  
    .noresultbox{
      .noresultbox-inner{
        
        .icon{
        }
        p{
        }
      }
    }
    .display-results{
  
    }
   
  }
  
  }

  .right-section{
    float: none;
    width: 100%;
      .feedBack-box {
        .center {
          padding: 20px;
          .feedBack-icon {
        }
          .feedBack-box-right {
            
            p {
          }
          .read-more {
           
        }
        }
      }
    }
    .related-sec {
      position: relative !important;
      width: 100% !important;
      top: 0 !important;
      .line{
      }
      .related-title {
        padding: 20px 0px;
        font-size: 20px;
        line-height: 26px;
        padding-bottom: 15px;
    }
    .related-content {
      
      ul {
       
         li {
           a {
            span {
             
          }
        }
      }
    }
  }
  }
  }

}

@media screen and (max-width:768px){
  .title_gap {
    margin-bottom: 20px;
}
  .right-section .related-sec .related-title {
    font-size: 19px;
  }
  .left-section .alphabets ul li {
    width: calc(7% - 2px);
}

    .search-form {
      padding: 15px;
        .item {
      }
      .item.select-item{
        width: 100%;
      }
      .item.input-item{
        width: calc(100% - 65px);
        margin-left: 0;
        margin-top: 15px;
        .input-input {
      }
      .input-input:focus{
      }
      .input-dropdown{
        ul{
          li{
          }
        }
      }
      }
      .item.icon-item{
        margin-top: 15px;
        .icon {
      }
      .icon:hover {
    }
      }
    }
  



 .search-form.product .item.select-item {
  width: 70%;
}
.search-form .item.select-item.product {
  width: calc(30% - 25px);
}

 .search-form .item.select-item.input-item.product-search {
  width: calc(96% - 50px);
  margin-left: 0px;
}

.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item .study-item-select {
  position: relative;
  width: calc(55% - 5px);
}
.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item p:nth-of-type(1) {
  width: calc(45% - 0px);
}
.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item p {
  width: calc(55% - 0px);
}


}

@media screen and (max-width:650px){
  .left-section .results-div .accordion-box .accordion-item .accordion-body ul li {
    margin-bottom: 8px;
  } 
  .right-section .related-sec .related-title {
    padding:18px 0px;
    font-size: 18px;
  }
  .breadcrumb ol li span {
    margin: 0px 8px;
  }
  .left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item .study-item-select{
    // width: calc(60% - 5px);
  }
  .left-section  .results-div   .accordion-box  .accordion-item .accordion-head .accordion-title{
            font-size: 14px;
          }
  .breadcrumb ol {
    padding: 12px 0;
}

.right-section .feedBack-box .center .feedBack-box-right .read-more {
  padding: 4px 16px;
}


.title-item{
  margin-top: 20px;
  .title{
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item p {
  line-height: 16px;
  padding: 6px 5px;
}

.left-section .results-div {
  min-height: 300px;
}

.left-section .results-div .accordion-box .accordion-item .accordion-body ul {
  margin: 10px 20px;
}

.right-section .feedBack-box .center {
  padding: 15px;
}

.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item .video_box {
  
  height: 250px;
}

}
@media screen and (max-width:500px){
 
  .left-section .alphabets ul li {
    width: calc(11% - 2px);
}
.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item p:nth-of-type(1){
  width: 100%;
  box-sizing: border-box;
  float: none;
}
.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item p{
  width: 100%;
  box-sizing: border-box;
  float: none;
}
.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item .study-item-select{
  width: calc(100% - 5px);
  // float: none;
}
.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item p.pdf{
  float:none;
  width:100%;
}

.left-section  .results-div   .accordion-box  .accordion-item .accordion-head .accordion-title{
  font-size: 13px;
}

.right-section .related-sec .related-title {
  padding: 16px 0px;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 12px;
}

 .search-form .item.select-item.input-item.product-search {
  width: calc(94% - 50px);
}

.left-section .results-div .noresultbox {
  position: relative;
  width: 100%;
}
.search-form .item.icon-item .icon {
  height: 38px;
}

.left-section .results-div .accordion-box .product_main_title {
  font-size: 14px;
}
.left-section .results-div .accordion-box .accordion-item .accordion-body .product_title {
  font-size: 13px;
}
.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item .video_box {
  
  height: 230px;
}

.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item {

  padding: 0px 0px;
}

}

@media screen and (max-width:400px){
  .title_gap {
    margin-bottom: 15px;
}
 

.title-item{
  margin-top: 15px;
  .title{
    font-size: 18px;
    margin-bottom: 12px;
  }
}


 .search-form.product .item.select-item {
  width: 100%;
}
 .search-form.product .item.select-item.product {
  width: 100%;
  margin: 0;
  margin-top: 15px;
}
 .search-form.product .item.select-item.input-item.product-search {
  width: calc(92% - 50px);
}
.left-section .results-div .accordion-box .accordion-item .accordion-head {
  padding: 10px 0px;
}

.left-section .results-div .accordion-box .accordion-item .accordion-body .inner-item {
  padding: 0px 10px;
}

.left-section .results-div .accordion-box .accordion-item .accordion-body ul {
  margin: 5px 10px;
}

.left-section .results-div .accordion-box .accordion-item .accordion-body ul li {
  margin-bottom: 5px
}

.left-section .results-div .accordion-box .accordion-item .accordion-body .study-box .study-box-inner .study-item .video_box {
 height: 200px;
}



}
