body.google_translate{
    .device-layer{
        top: 115px;
    }
    .device-nav{
        top: 115px;
    }
    .dummy-header{
    height: 120px;
    position: relative;
    }
    .header{
        top: 53px;
    }

}


.header.introjs-fixParent{
    position: absolute;
}
.introjs-helperNumberLayer{
    display: none;
}
.introjs-helperLayer{
    border-radius: 0;
    top:0 !important;
    height: 59px !important;
}
.introjs-tooltip{
    min-width: 380px;
    max-width: 380px;
    padding: 25px 20px;
    border-radius: 0;
}
.introjs-bullets ul{
    display: none;
}
.introjs-tooltipbuttons{
    margin-top: 20px;
}
 .introjs-button.introjs-skipbutton.introjs-donebutton, .introjs-button.introjs-skipbutton{
    background: #0460a9;
    color: #fff;
    padding: 8px 15px;
    text-shadow: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    margin-right: 10px;
}
.introjs-prevbutton.introjs-fullbutton, .introjs-nextbutton.introjs-fullbutton{
    background: #0460a9;
    color: #fff;
    padding: 8px 15px;
    text-shadow: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
}

.header.survey{
   
}
.top-header.survey{
    z-index: 120;
    position: static;
}
.top-header{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 20;
    height: 53px;
    .google-translate.survey-google{
        position: fixed;
        right: 2%;
        top: 55px;
        z-index: 102;
        background: #fff;
        padding: 10px;
    }
.google-translate {
    position: relative;
    background: #fff;
    padding: 0px 0;
    #google_translate_element {
        float: right;
        width: 183px;
        .goog-te-gadget .goog-te-combo {
            margin: 4px 0;
            padding: 6px 10px;
            width: 100%;
        }
        .skiptranslate.goog-te-gadget{
            div{
                display: table !important;
                margin: 0;
                padding: 0;
                width: 100%;
            }
        }
    }
}
}
.header{
    position: fixed;
    width: 100%;
    z-index: 20;
    left: 0;
    top: 0;
    background: #fff;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 #EDEDED;
    height: 67px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.10);
    
    .container{
        padding: 0px 20px;
        padding-left: 8%;
        padding-right: 2%;
        width: calc(100% - 10%);
        .logo{
            width: 170px;
            margin: 18px 0px;
            cursor: pointer;
            img{
                width: 100%;
            }
        }
      
        .google-translate-btn.opacity{
            opacity: 0;
        }
        .google-translate-btn{
            position: relative;
            margin: 18px 0px;
            padding: 8px 0px;
            padding-right: 25px;
            line-height: 16px;
            margin: 18px 0px;
            cursor: pointer;
            span{
                position: absolute;
                width: 18px;
                height: 18px;
                background-image: url(../../assets/google-translate.svg);
                background-repeat: no-repeat;
                background-size: 18px;
                background-position: center;
                right: 0;
            }
        }
        .navigation{
            position: relative;
            margin-right: 60px;
            ul{
                position: relative;
                text-align: center;
                margin: auto;
                float: none;
                li{
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    p{
                        position: relative;
                        display: inline-block;
                        padding: 24px 10px;
                        margin: 0;
                        text-align: center;
                        box-shadow: none;
                    }
                    p:after {
                        content: "";
                        position: absolute;
                        display: block;
                        bottom: 1rem;
                        left: 10px;
                        right: 10px;
                        margin-left: 0;
                        border-bottom: 0.0625rem solid rgba(255, 255, 255, 0);
                        transition-property: border-color;
                        transition-duration: 200ms;
                        transition-timing-function: ease-out;
                        transition: all .2s ease-in-out;
                    }
                    p:hover:after {
                        border-bottom: 0.0625rem solid currentColor;
                    }
                }
                li.active p:after {
                    border-bottom: 0.0625rem solid currentColor;
                }
            }
        }
    }
    .device-layer{
        position: fixed;
        top: 61px;
        left: 0;
        list-style: none;
        margin: 0;
        width: 100%;;
        bottom: 0;
        background: rgba(0, 0, 0, 0.52);
        z-index: -1;
    }
   
    
    .device-nav{
        position: fixed;
        top: 61px;
        left: 0;
        padding-bottom: 20px;
        list-style: none;
        margin: 0;
        width: 100%;
        ul{
            background-color: #fff;
            position: relative;
            li:first-child{
                border-top: none;
            }
            li{
                position: relative;
                cursor: pointer;
                border-top: 1px solid #e2e2e2;
                position: relative;
                display: block;
                padding: 20px 0;
                margin: 0px 4%;
                box-shadow: none;
            }
        }
    }

}

.dummy-header{
  height: 67px;
}

.dummy-header.banner-img:after{
    content: "";
    position: absolute;
    left: 6%;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
     background-image: url(../../assets/home-banner-patch.jpg);
}

.dummy-header.survey{
    // position: absolute;
    // top: 0;
    // left: 0;
}
.dummy-header::before {
    content: "";
    position: absolute;
    top: 0;
    display: block;
    height: 100%;
    background-image: url(../../assets/pattern.png);
    background-repeat: repeat;
    left: 1px;
    width: 6%;
    background-position: bottom left;
  }

  body.introActive .top-header{
      z-index: 0;
  }

@media screen and (max-width:1280px){
   
    .header.google{
    }
    .top-header{}
    .header{
        height:60px;
        .container{
          
            .logo{
                width: 160px;
                margin:15px 0px;
            }
            .google-translate-btn{
                margin: 14px 0px;
                span{
                }
            }
            .navigation{
                ul{
                    li{
                        a{
                            padding: 22px 10px;
                        }
                       
                    }
                    li.active a:after {
                       
                    }
                }
            }
        }

    }

    .dummy-header{
        height: 60px;
        position: relative;
      }
      .dummy-header.google{
        height: 115px;
      }
  
      
}
@media screen and (max-width:1024px){
    .dummy-header.banner-img:after {
        background-size: 140%;
    }
}

@media screen and (max-width:1000px){
    .dummy-header.banner-img:after {
        background-size: 100%;
    }
    .header .container {
        padding: 0px 20px;
        padding-left: 4%;
        padding-right: 2%;
        width: calc(100% - 6%);
    }
    .header.google{
    }
    .top-header{}
    .header{

        .container{
          
            .logo{
                width: 150px;
            }
            .google-translate-btn{
                margin: 0;
                margin-top: 5px;
                span{
                }
            }
            .navigation{
               display: none;
            }
            .device-menu{
                display: block;
                position: absolute;
                bottom: 6px;
                right: 15px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                span{
                    background: #1F140C;
                    height: 2px;
                    transition: all 0.3s cubic-bezier(0.7, 0, 0.36, 0.63);
                    -webkit-transition: all 0.3s cubic-bezier(0.7, 0, 0.36, 0.63);
                    width: 100%;
                    opacity: 1;
                    top: 10px;
                    right: 0;
                    position: absolute;
                }
                span:before {
                    content: "";
                    background: #1F140C;
                    height: 2px;
                    margin-top: 0;
                    position: absolute;
                    width: 100%;
                    transition: all 0.3s cubic-bezier(0.7, 0, 0.36, 0.63);
                    top: -7px;
                }
               span:after {
                    content: "";
                    background: #1F140C;
                    height: 2px;
                    margin-top: 0;
                    position: absolute;
                    width: 100%;
                    transition: all 0.3s cubic-bezier(0.7, 0, 0.36, 0.63);
                    top: 7px;
                }
            }
            .device-menu.active{
                span{
                    height: 0;
                }
                span:before {
                    transform: translateY(0.500rem) rotate(45deg) translateX(-0.0625rem);
                    -ms-transform-origin: bottom;
                    transform-origin: bottom;
                }
               span:after {
                transform: translateY(-0.500rem) rotate(-45deg) translateX(0.0625rem);
                }
            }
        }
      

    }

    .dummy-header{
      }
      .dummy-header.google{
      }
  
      
}

@media screen and (max-width:768px){
    .dummy-header::before{
        background-size: 105px;
    }
    .dummy-header.banner-img:after {
        background-size: 810px;
    }
}

@media screen and (max-width:650px){
    .dummy-header::before{
        background-size: 75px;
    }
    .dummy-header.banner-img:after {
        background-size: 665px;
    }
}

@media screen and (max-width:500px){
    .dummy-header.banner-img:after {
        background-size: 580px;
    }

    .top-header .google-translate #google_translate_element .goog-te-gadget .goog-te-combo {
      padding: 4px 8px;
  }
  .header .device-nav.google {
    top: 114px;
}
.device-layer.google{
    top: 114px;
}
}

@media screen and (max-width:400px){
    .dummy-header::before{
        background-size: 58px;
    }

    .dummy-header.banner-img:after {
        background-size: 485px;
    }
}