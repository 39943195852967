.library-div{
    position: relative;
    margin-top: 20px;
    min-height: 350px;
    .pagination-block{
        position: relative;
       
        .pagination-head{
            border-bottom: 2px solid #0460a9;
            padding: 18px 0;
            ul{
                position: relative;
                li{
                    font-weight: 700;
                    padding: 0 5px;
                    color: #023761;
                    box-sizing: border-box;
                    float: left;
                }
            }
        }
        .pagination-head.fixed{
            position: fixed;
            left: 8%;
            right: 2%;
            top: 61px;
            background: #fff;
            z-index: 1;
        }
        .pagination-body.fixed{
            padding-top: 53px;
        }
        .pagination-body{
            position: relative;
            ul{
                position: relative;
                border-bottom: 1px solid #dcdcdc;
                li{
                    position: relative;
                    color: #000;
                    padding: 0 5px;
                    vertical-align: top;
                    box-sizing: border-box;
                    line-height: 22px;
                    padding: 10px 5px;
                    float: left;
                    word-wrap: break-word;
                    ul{
                        position: relative;
                        border: none;
                        li{
                            width: 100%;
                            border-top: 1px solid #e2e2e2;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            padding-left: 0;
                            padding-right: 0;
                        }
                        li:first-child{
                            border-top: none;
                            padding-top: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1280px){
    .library-div .pagination-block .pagination-body ul li{
        line-height: 21px;
    }
}

@media screen and (max-width:650px){
    .library-div .pagination-block .pagination-body ul li{
        line-height: 20px;
    }

    .library-div .pagination-block .pagination-head {
        padding: 10px 0;
    }
    .library-div .pagination-block .pagination-head ul li {
        line-height: 18px;
    }
}